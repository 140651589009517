var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "UserInfoSetting" }, [
    _c(
      "div",
      { staticClass: "UserInfoSetting__main" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              "label-position": "left",
              "label-width": "80px",
            },
          },
          [
            _vm.setType === 1
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "账号" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "userName", $$v)
                            },
                            expression: "form.userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.nickName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "nickName", $$v)
                            },
                            expression: "form.nickName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.setType === 2
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "新密码" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "再次输入" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.repeatpassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "repeatpassword", $$v)
                            },
                            expression: "form.repeatpassword",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.setType === 3
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.phonenumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phonenumber", $$v)
                            },
                            expression: "form.phonenumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-form-item", { attrs: { label: "验证码" } }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            staticClass: "w100 marginRight10",
                            model: {
                              value: _vm.form.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              class: { grey: _vm.dis, blue: !_vm.dis },
                              attrs: { disabled: _vm.dis, type: "primary" },
                              on: { click: _vm.getCode },
                            },
                            [
                              !_vm.dis
                                ? _c("span", [_vm._v("发送验证码")])
                                : _c("span", [
                                    _vm._v(
                                      "重新发送(" + _vm._s(_vm.count) + "s)"
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.setType === 4 ? _c("div", [_vm._v("123")]) : _vm._e(),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "UserInfoSetting__footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", plain: "" },
            on: { click: _vm.onSubmit },
          },
          [_vm._v("保 存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }