<template>
  <div class="UserInfoSetting">
    <div class="UserInfoSetting__main">
      <el-form ref="form" :model="form" label-position="left" label-width="80px">
        <div v-if="setType === 1">
          <el-form-item label="账号">
            <el-input v-model="form.userName"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="form.nickName"></el-input>
          </el-form-item>
        </div>
        <div v-if="setType === 2">
          <el-form-item label="新密码">
            <el-input v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="再次输入">
            <el-input v-model="form.repeatpassword"></el-input>
          </el-form-item>
        </div>
        <div v-if="setType === 3">
          <el-form-item label="手机号">
            <el-input v-model="form.phonenumber"></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <div class="flex">
              <el-input v-model="form.code" class="w100 marginRight10"></el-input>
              <el-button :class="{ grey: dis, blue: !dis }" :disabled="dis" type="primary" @click="getCode">
                <span v-if="!dis">发送验证码</span>
                <span v-else>重新发送({{ count }}s)</span>
              </el-button>
            </div>
          </el-form-item>
        </div>
        <div v-if="setType === 4">123</div>
      </el-form>
    </div>
    <div class="UserInfoSetting__footer">
      <el-button type="primary" plain @click="onSubmit">保 存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfoSetting",
  props: {
    type: {
      type: Number,
      default: 0, // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      setType: "",
      form: {
        userId: "",
        userName: "",
        nickName: "",
        password: "",
        repeatpassword: "",
        phonenumber: "",
        code: "",
      },
      //
      dis: false,
      timer: null, //设置计时器,
      count: "",
    };
  },
  created() {
    this.setType = this.type;
    this.getUserInfo();
  },
  methods: {
    getCode() {
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.dis = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.dis = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    getUserInfo() {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.form.userId = userInfo.userId;
      this.form.userName = userInfo.userName;
      this.form.nickName = userInfo.nickName;
      this.form.phonenumber = userInfo.phonenumber;
    },
    onSubmit() {
      if (this.form.password !== this.form.repeatpassword) {
        this.$message({ message: "重新输入密码与新密码不匹配", type: "warning" });
        return;
      }
      let url;
      if (this.setType === 2) {
        url = `${this.baseUrls.user}/resetPwd`;
      } else {
        url = `${this.baseUrls.user}/edit`;
      }
      const data = this.form;
      this.topsAjax.post(url, data).then((response) => {
        if (this.setType !== 2) sessionStorage.setItem("userInfo", JSON.stringify(response));
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.$emit("closedDialog");
      });
    },
  },
};
</script>

<style lang="scss">
.UserInfoSetting {
  &__main {
    .grey {
      background-color: #edeef1;
      border-color: #edeef1;
      color: #666;
    }
    .blue {
      background-color: rgb(236, 245, 255);
      border-color: rgb(236, 245, 255);
      color: rgb(236, 245, 255);
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
